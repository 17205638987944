import * as React from 'react';
import axios from "axios";
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {useForm} from 'react-hook-form';
import {useLocation} from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLoadScript} from "@react-google-maps/api"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DateRangePicker } from 'rsuite';
import Modal from '../modal/Modal';
import MapComponent from '../Map/MapComponent';
import s from './views.module.css';
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";



function ComplaintViews({ langCH }){
    const {t, i18n} = useTranslation()
    const BaseURL = process.env.REACT_APP_URL_API;
    const [isModal, setIsModal] = useState(false)
    const { register, handleSubmit,  formState: { errors }} = useForm();
    const [value, setValue] = useState([null, null]);
    const [data, setData] = useState();
    const [isData, setIsData] = useState(false);
    const [name, setName] = useState('');
    const [complete, setComplete] = useState(false);
    const [negatives, setNegatives] = useState()
    const [file, setFile] = useState()
    const [latitude, setLat] = useState(51)
    const [longitude, setLong] = useState(72)
    const [GeoError, setGeoError] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [dateStart, setDateStart] = useState()
    const [dateEnd, setDateEnd] = useState()

    let lng_1;
    let lan_1;

    const handleChange = (newValue) => {setValue(newValue)};
    const location = useLocation();
    const fullCheckbox = [];

    useEffect(() => {
        if(value[0] !== undefined && value[0] !== null){
            setDateStart(dayjs(value[0]).format('YYYY-MM-DD'));
            setDateEnd(dayjs(value[1]).format('YYYY-MM-DD'));
        }
    }, [value])


    // get to id of the page
    const locationSplit = location.pathname.split('/')
    const idPage = locationSplit[locationSplit.length - 1]

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(showPosition, Error);

        function showPosition(position) {
            if (!isReady) {
                setLat(position.coords.latitude)
                setLong(position.coords.longitude);
                setIsReady(true)
            }
        }

        function Error(err){
            const geo = document.getElementById('geo');
            setGeoError(true)
            geo.innerHTML = ("Geolocation is not supported by this browser.");
        }
    })


    // get name of the complaints
    useEffect(() => {
        axios.get(`${BaseURL}/complaints?`,{ params: { id: idPage } })
            .then(res => {
                if(!isData){
                    let langIndex = 2
                    for(let i = 0; i < 2; i++){
                        if(res?.data[0]?.translations[i].lang === langCH){
                            langIndex = i;

                        }
                    }
                    setName(res?.data[0]?.translations[langIndex].name)
                    setData(res?.data[0]?.negatives)
                }
            })
    }, [langCH])


    // check what data is correct for this page

    useEffect(() => {
        const negative = []
        if(!complete){
            if(data !== undefined){
                for(let i =0; i < data.length; i++){
                    let langIndex = 2
                    for(let j=0; j<2; j++){
                        if(data[i].translations[j].lang === langCH){
                            langIndex = j
                        }
                    }
                    negative.push({
                        id : data[i].id,
                        name: data[i]?.translations[langIndex]?.name
                    })
                }
                setNegatives(negative)
            }
        }
    }, [data])// eslint-disable-line


    // checkbox logic
    const checkBox = (id) => {
        if(fullCheckbox[0] !== undefined){
            let isPossible = false
            for(let i = 0; i < fullCheckbox.length; i++){
                if(id === fullCheckbox[i]){
                    fullCheckbox.splice(i, 1);
                    const elem = document.getElementById(`par_${id}`)
                    elem.style.color = "#454545"
                    isPossible = true;
                }
            }
            if(!isPossible){
                fullCheckbox.push(id)
                const elem = document.getElementById(`par_${id}`)
                elem.style.color = "#3379FF"
            }
        }
        else{
            fullCheckbox.push(id)
            const elem = document.getElementById(`par_${id}`)
            elem.style.color = "#3379FF"
        }
    }



    const checkItem = negatives !== undefined ? negatives.map(res => {
        return(
        <div className={s.inputC} key={res.id}>
        <input type={'checkbox'} onClick={() => {checkBox(res.id)}} id={'check'} /><p className={`${s.p} ${s.PLzero}`} id={`par_${res.id}`}>{res.name}</p>
        </div>)
    }):<div></div>


    function handleFile(e){
        setFile(e.target.files[0])
    }


    const onSubmit = (data) => {
        const formdata = new FormData();
        formdata.append('image', file, file.name)

        const position = [];
        const lnt = document.getElementById('lat');
        const lam = document.getElementById('lng');
        if(lnt.innerHTML !== null){
            position.push(lnt.innerHTML);
            position.push(lam.innerHTML);
        }else{
            position.push(latitude);
            position.push(longitude);
        }

        axios.post(`${BaseURL}/claim`, {
            complaint_id: idPage,
            name: data?.place,
            subname: 'mobile-version',
            region_id: 1,
            settlement: 1,
            date_start: dateStart,
            date_end: dateEnd,
            negatives: fullCheckbox,
            comment_text: data?.comment,
            score: 4,
            photos: formdata,
            comment: data?.comment,
            longitude: position[0],
            lat: position[1],
            email:data?.email
        })
            .then(res => { setIsModal(true)})
            .catch(err => { console.log(err)})

        setTimeout(() =>{

        }, 3000)
    };


    return(
        <>

            {!isModal && <div className={'complaint_views'}>
                <div className="background_cover"/>
                <div className="views_content">
                    <div className="wrapper">
                        <div className="header">
                            <div className="back">
                                <Link to={'/'}>
                                    <img src="/img/back.svg" alt="back"/>
                                    <span>{t("back")}</span>
                                </Link>
                            </div>
                            <div className={s.content}>
                                <div className={s.line}/>
                                <h5 className={s.h5}>{name}</h5>
                            </div>

                        </div>
                        <p className={s.p}>{t("labels.place")}<span
                            className={s.red}>*</span></p>
                        <p className={s.pItalic}>{t("labels.place_1")}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <input type="text" className={s.placeName} {...register("place", { required: true })} />
                            {errors.place && <p className={`${s.p}`} style={{color: `red`, position:`relative`, bottom: `10px` }}>{t("labels.err")}</p>}
                            <p className={`${s.p} ${s.wFull}`}>{t("labels.map")}</p>

                            {!GeoError &&
                                <>
                                    <MapComponent latitude={latitude} longitude={longitude} />
                                </>
                            }

                            <p style={{marginBottom: `20px`, marginTop: `20px`}} id={'geo'} className={s.p}></p>
                            <label>{t("labels.email")}</label>
                            <input type="email" id={"email"} className={s.placeName} {...register("email", { required: true })}/>
                            {errors.email && <p className={`${s.p}`} style={{color: `red`, position:`relative`, bottom: `10px` }}>{t("labels.err")}</p>}
                            <label>{t("labels.date")}</label>
                            <div className={s.dateWrapper}>
                                {/*<LocalizationProvider dateAdapter={AdapterDateFns} >*/}
                                {/*    <Stack spacing={3}>*/}
                                {/*        <MobileDatePicker*/}
                                {/*            label=""*/}
                                {/*            inputFormat="MM/dd/yyyy"*/}
                                {/*            value={value}*/}
                                {/*            onChange={handleChange}*/}
                                {/*            renderInput={(params) => <TextField {...params} />}*/}
                                {/*            renderInput={(params) => <TextField {...params} />}*/}
                                {/*            className={s.wiMobile}*/}
                                {/*        />*/}
                                {/*    </Stack>*/}
                                {/*</LocalizationProvider>*/}
                                <DateRangePicker onOk={e => setValue(e)} placeholder={t("labels.date")}/>
                            </div>

                            <label>{t("labels.addPic")}</label>
                            {file !== undefined? <p style={{marginLeft: `40px`, marginBottom: `5px`, maxWidth: `80%`, overflow: `auto`}}>{file.name}</p> : null}
                            <input type="file" name={"picture"} id={'file'} className={s.picture} onChange={(e) => handleFile(e)} accept="image/png, image/gif, image/jpeg, image/jpg"/>
                            <label htmlFor="file" className={s.file}><img src="/img/file.svg" alt="file" /><p>{t("labels.pastePic")}</p></label>
                            <label>{t("labels.disLike")}</label>
                            <div className={s.selectionW}>
                                {checkItem}
                            </div>
                            <label>{t("labels.more")}</label>
                            <textarea type="text" className={s.placeNameT} {...register("comment")}/>
                            <button className={s.btnNext} >{t("forward")}
                            </button>
                        </form>
                    </div>
                </div>
            </div>}

            {isModal && <Modal setIsModal={setIsModal}/>}
        </>
    )
}

export default ComplaintViews