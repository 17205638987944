import s from './navbar.module.css'
import {useEffect, useState} from "react";
import { useTranslation } from 'react-i18next'
import {useLocation} from "react-router-dom";

function Navbar({setLangCH}){
    const {t, i18n} = useTranslation()
    const [IsMenu, setIsMenu] = useState(null)


    useEffect(() => {
        changeColor(i18n.language)
    },[])

    useEffect(() =>{
        const menu = document.getElementById('hamburger');
        if(IsMenu){
            document.body.style.position = 'relative';
            menu.classList.remove(s.disActive)

            menu.classList.add(s.active)
        } else if(IsMenu === false){
            menu.classList.remove(s.active)
            menu.classList.add(s.disActive)
        }

    }, [IsMenu])

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang)
        changeColor(lang)
        setLangCH(lang)
        setIsMenu(false)
    }

    const changeColor = (lang) => {
        const langList = ['ru', 'en', 'kz']
        for(let i=0; i < langList.length; i++){
            const langId = document.getElementById(langList[i])
            if(langList[i] === lang){
                langId.classList.add(s.btnActive)
            } else {
                langId.classList.remove(s.btnActive)
            }
        }
    }


    return(
        <div className={"navbar"} >
            <img src="/img/menu.svg" alt="menu" onClick={() => {setIsMenu(!IsMenu)}}/>
            <div className={`${s.hamburger} `} id={'hamburger'}>
                <div className={s.cancelWp}><img src="/img/cancel.svg" alt="cancel" className={s.cancel} onClick={() => {setIsMenu(!IsMenu)}}/></div>
                <div className={s.link}>
                    <a href="https://kazakhstan.travel/" target={'_blank'} className={s.a}>{t("kazakhTravel.where")}</a>
                    <a href="https://kazakhstan.travel/" target={'_blank'} className={s.a}>{t("kazakhTravel.toDo")}</a>
                    <a href="https://kazakhstan.travel/" target={'_blank'} className={s.a}>{t("kazakhTravel.plan")}</a>
                </div>
                <div className={s.line} />
                <div className={s.partners}>
                    <a href="https://eqonaq.kz/?lang=kz" target={'_blank'} className={s.a}>{t("partners.Eqonaq")}</a>
                    <a href="https://altynorda.kazakhstan.travel/" target={'_blank'} className={s.a}>{t("partners.Altyn")}</a>
                    <a href="https://kazakhstan.travel/photobank" target={'_blank'} className={s.a}>{t("partners.Photobank")}</a>
                    <a href="https://safe.kazakhstan.travel/" target={'_blank'} className={s.a}>{t("partners.SafeTravels")}</a>
                </div>
                <div className={s.line} />
                <div className={s.lang}>
                    <button className={s.btn} onClick={() => changeLanguage('kz')} id={'kz'}>Қазақ</button>
                    <button className={s.btn} onClick={() => changeLanguage('ru')} id={'ru'}>Русский</button>
                    <button className={s.btn} onClick={() => changeLanguage('en')} id={'en'}>English</button>
                </div>
            </div>
        </div>
    )
}

export default Navbar;