import {useState, useEffect, useTransition} from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {useTranslation} from "react-i18next";

function Complaints({langCH}){
    const {t, i18n} = useTranslation()
    const BaseURL = process.env.REACT_APP_URL_API
    const [data, setData] = useState()
    const [isData, setIsData] = useState(false)
    const [isComplete, setIsComplete] = useState(false)
    const [complain, setComplain] = useState()


    useEffect(() =>{
        let chLang = false
        axios.get(`${BaseURL}/complaints`)
            .then(res => {
                if(!isData || !chLang){
                    setData(res?.data)
                    setIsData(true)
                    chLang = true
                }
            })
            .catch(err => {console.log(err)})
    },[langCH])

    useEffect(() => {
        const complaints = [];
        let chLang = false
        if(!isComplete || !chLang){
            if(isData || !chLang){
                if(data !== undefined){
                    let langIndex = 2;
                    for(let i = 0; i<data.length; i++){
                        for(let j=0; j<2; j++){
                            if(data[i].translations[j].lang === langCH){
                                langIndex = j
                            }
                        }
                        complaints.push({
                            name: data[i].translations[langIndex]?.name,
                            id: data[i].id
                        })
                    }
                    setComplain(complaints)
                    setIsComplete(true)
                }
            }
        }

    }, [data])


    const [pageIndex, setPageIndex] = useState(null)

    useEffect(() => {
        const btn_link = document.getElementById('link_1')
        if(complain !== undefined){

            if(pageIndex !== null){
                const btn_ids = [];
                let btn_id;

                // id array
                for(let i=0; i<complain.length; i++){
                    btn_ids.push(`btn_${complain[i].id}`)
                }

                // check correction of id
                for(let i=0; i<complain.length; i++){
                    if(btn_ids[i] === `btn_${pageIndex}`){
                        btn_id = document.getElementById(btn_ids[i])
                    }else{
                        document.getElementById(btn_ids[i]).classList.remove('active')
                    }
                }

                // add acive class to correct id
                btn_id.classList.add('active')
                btn_link.classList.remove('disable')
            }else{
                btn_link.classList.add('disable')
            }

        }

    }, [pageIndex])




    const typesComplaints = complain !== undefined? complain.map((res, index) => <Link to={`/complaint-views/${res.id}`} key={res.id} id={`btn_${res.id}`}>{res.name}</Link>): ''
    
    return(
        <div className={"complaints_wrapper"}>
            <img src="/img/background.png" alt="background" className={'banner_img'}/>
            <div className={'Complaints'}>
                <h4>{t("cause")}</h4>
                <div className="types">
                    {typesComplaints}
                </div>
            </div>
            <div className="inform">
                <p>{t("complaint.para_1")}</p>
                <p>{t("complaint.para_2")}</p>
                <p>{t("complaint.para_3")}</p>
            </div>
            <div className="btn-wrapper">
                <a target={'_blank'} href={'https://shagym.kazakhstan.travel/ru/registry'} id={'link_1'}>{t("btn")}</a>
            </div>
        </div>
    )
}

export default Complaints;